require('./scss/styles.scss');
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'popper.js/dist/popper.min';
import 'bootstrap/dist/js/bootstrap.min';
import 'daemonite-material/js/material.min';
import 'select2/dist/js/select2.min';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'typeahead.js/dist/typeahead.jquery.min';
import './js/tagmanager';
import './js/scripts.js';
